import Http from "../utils/Http";
import * as authActions from "../store/auth/actions";
import * as signUpActions from "../store/auth/actions";
import Transformer from "../utils/Transformer";
import * as constant from "../../config/constant";

// export function signUp(data: any) {
//   if (constant.default.platform === 'web') {
//     Http.defaults.headers.common['Accept-Language'] = 'en'
//     if (
//       localStorage.getItem('access_token') &&
//       localStorage.getItem('access_token') !== null &&
//       localStorage.getItem('access_token') !== undefined
//     ) {
//       Http.defaults.headers.common['auth'] = localStorage.getItem(
//         'access_token',
//       );
//     }
//   } else {
//     Http.defaults.headers.common['Accept-Language'] = 'en'
//     Http.defaults.headers.common['auth'] = constant.default.accessToken;
//   }

//   return (dispatch: any) =>
//     new Promise((resolve, reject) => {
//       Http.post('api/auth/signup', data)
//         .then(res => {
//           return resolve(res);
//         })
//         .catch(err => {
//           const statusCode = err.response.status;
//           const data = {
//             error: err.response.data,
//             statusCode,
//           };
//           return reject(data);
//         });
//     });
// }

export function emailExistCheck(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common["auth"] =
        localStorage.getItem("access_token");
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common["auth"] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/auth/emailExistCheck", data)
        .then((res) => {
          if (res.status === 200) {
          }
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * logout user
 *
 * @returns {function(*)}
 */
export function logout() {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common["auth"] =
        localStorage.getItem("access_token");
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common["auth"] = constant.default.accessToken;
  }
  return (dispatch: any) => {
    return Http.get("api/auth/logout")
      .then((res) => {
        const data = Transformer.fetch({});
        dispatch(authActions.authLogout(data));
      })
      .catch((err) => {});
  };
}

export function verifyEmail(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common["auth"] =
        localStorage.getItem("access_token");
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common["auth"] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/auth/verify-email", data)
        .then((res) => {
          const data = Transformer.fetch(res.data);
          if (res.status === 200) {
          }
          return resolve(data);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * sign up user
 *
 * @param credentials
 * @returns {function(*)}
 */
export function resendActivationLink(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common["auth"] =
        localStorage.getItem("access_token");
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common["auth"] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/auth/resend-verification-link", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * forgot password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function forgotPasswordLink(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common["auth"] =
        localStorage.getItem("access_token");
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common["auth"] = constant.default.accessToken;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/auth/forgot-password", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * reset password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function resetPassword(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }
  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/auth/reset-password", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * sign in user
 *
 * @param credentials
 * @returns {function(*)}
 */

export function signIn(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common["auth"] =
        localStorage.getItem("access_token");
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common["auth"] = constant.default.accessToken;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/auth/login", data)
        .then((res) => {
          const data = Transformer.fetch(res.data);
          if (data.user.isProfileComplate === false) {
            dispatch(signUpActions.signUpData(data.user));
            // dispatch(authActions.authLoginSuccess(data));
          } else {
            dispatch(authActions.authLoginSuccess(data));
          }
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}

/**
 * sign in user
 *
 * @param credentials
 * @returns {function(*)}
 */

export function signInAfterComplateProfile(data: any) {
  return (dispatch: any) => dispatch(authActions.authLoginSuccess(data));
}

/**
 * get user using token
 *
 * @param credentials
 * @returns {function(*)}
 */
export function getUserUsingToken(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get("api/auth/practitioner", data)
        .then((res) => {
          const data = Transformer.fetch(res.data);
          dispatch(authActions.authLoginSuccess(data));
          dispatch(authActions.isLocalStorageSet(data));
          return resolve(res);
        })
        .catch((err) => {
          return reject();
        });
    });
}

/**
 * get user using session token
 *
 * @param credentials
 * @returns {function(*)}
 */
export function getUserUsingSessionToken(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      sessionStorage.getItem("access_token") &&
      sessionStorage.getItem("access_token") !== null &&
      sessionStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${sessionStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${sessionStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.get("api/auth/practitioner", data)
        .then((res) => {
          // const data = Transformer.fetch(res.data);
          // dispatch(authActions.authLoginSuccess(data));
          // dispatch(authActions.isLocalStorageSet(data));
          return resolve(res);
        })
        .catch((err) => {
          return reject();
        });
    });
}

/**
 * change password
 *
 * @param credentials
 * @returns {function(*)}
 */
export function changePassword(data: any) {
  if (constant.default.platform === "web") {
    Http.defaults.headers.common["Accept-Language"] = "en";
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token") !== undefined
    ) {
      Http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
  } else {
    Http.defaults.headers.common["Accept-Language"] = "en";
    Http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }

  return (dispatch: any) =>
    new Promise((resolve, reject) => {
      Http.post("api/auth/change-password", data)
        .then((res) => {
          return resolve(res);
        })
        .catch((err) => {
          const statusCode = err.response.status;
          const data = {
            error: err.response.data,
            statusCode,
          };
          return reject(data);
        });
    });
}
