import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserUsingSessionToken } from "../lib/apis/auth";

let userSessionAuth = function (ComposedComponent) {
  class UserSessionAuth extends Component {
    async componentDidMount() {
      // console.log(this.props);
      if (
        sessionStorage.getItem("access_token")
      ) {
        await this.GetTokenUser();
      }
    }

    GetTokenUser() {
      this.props
        .getUserUsingSessionToken()
        .then((response) => {
          // console.log("getUserUsingToken", response);
        })
        .catch((error, statusCode, message, e) => {
          // console.log("error", error);
          sessionStorage.removeItem("access_token");
          sessionStorage.removeItem("practitionerData");
          this.props.history.push(`${process.env.PUBLIC_URL}`);
        });
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    return {};
  };

  return connect(mapStateToProps, { getUserUsingSessionToken })(UserSessionAuth);
};

export default userSessionAuth;
