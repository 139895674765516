/* eslint-disable import/no-anonymous-default-export */
import dotenv from 'dotenv';

if (!dotenv) {
	throw new Error('Unable to use dot env lib');
}

const envFound = dotenv.config();
if (!envFound) {
	// This error should crash whole process
	throw new Error("⚠️ Couldn't find .env file ⚠️");
}

// export const API_URL = "http://192.168.1.67:4000/"
// export const API_URL = "http://linp.dev.innvonixtech.com/"
const API_URL = process.env.REACT_APP_API_URL

export { API_URL }

export const rpp = 10;

export const timeZone = 'Asia/Kolkata';

export const AES_SECRET_KEY = "@es-$ecretke#L!NP-@#&"