// import libs
import React, { lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";

// import components
// import PrivateRoute from './Private';
import PublicRoute from "./Public";
import AuthRoute from "./Auth";
import SessionAuthRoutes from "./SessionAuth";
import Layout from "../components/Layout";
import Header from "../global/Header";
import Footer from "../global/Footer";
import UserAuth from "./UserAuth.js";
import UserSessionAuth from "./UserSessionAuth.js";

import "../assets/css/style.scss";
import "../assets/css/base/fonts/flaticons/flaticon.css";
import "../assets/css/base/fonts/font/stylesheet.css";
// import { signUp } from '../lib/apis/auth';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GoToTop = () => {
  const routePath = useLocation();
  const onTop = () => {
    // setTimeout(() => {
    document
      .getElementsByTagName("body")[0]
      .scrollTo({ top: 0, behavior: "smooth" });
    window.scroll(0, 0);
    // }, 400);
  };
  useEffect(() => {
    onTop();
  }, [routePath.pathname]);

  return null;
};

const Login = lazy(() => import("../Pages/SignIn/index"));

const SignUp = lazy(() => import("../Pages/SignUp/index"));

const ComplateProfile = lazy(() => import("../Pages/SignUp/ComplateProfile"));

const Forgot = lazy(() => import("../Pages/Forgot/index"));

const Home = lazy(() => import("../Pages/Home/index"));

const Resetpassword = lazy(() => import("../Pages/resetpassword/index"));

const Profile = lazy(() => import("../Pages/Profile/index"));

const Terms = lazy(() => import("../Pages/CMS/Terms"));

const Privacy = lazy(() => import("../Pages/CMS/Privacy"));

const Search = lazy(() => import("../Pages/SearchPractitioners/index"));

const PaymentDetails = lazy(() => import("../Pages/PaymentDetails/index"));

const QuickContact = lazy(() => import("../Pages/QuickContacts/index"));

const EditProfile = lazy(() => import("../Pages/Profile/EditProfile"));

const Dashboard = lazy(() => import("../Pages/Dashboard/index"));

const ContactUs = lazy(() => import("../Pages/ContactUs/index"));

const ChangePassword = lazy(() => import("../Pages/ChangePassword/index"));

const Page404 = lazy(() => import("../Pages/Page404/index"));

const Preview = lazy(() => import("../Pages/Preview/index"));

const LandingPage = lazy(() => import("../Pages/LandingPage"));

const BlogList = lazy(() => import("../Pages/Blogs/index"));

const BlogDetails = lazy(() => import("../Pages/Blogs/description"));

const Routes = (props: any) => (
  <Router>
    <Header {...props} />
    <Layout>
      <GoToTop />
      <Switch>
        {/* Auth */}
        <PublicRoute
          path={`${process.env.PUBLIC_URL}/login`}
          component={UserAuth(Login)}
          newProps={props}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/sign-up`}
          component={SignUp}
        />

        <SessionAuthRoutes
          path={`${process.env.PUBLIC_URL}/complate-profile`}
          component={UserSessionAuth(ComplateProfile)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/preview/:user_slug?`}
          component={Preview}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/profile/:user_slug?/:zipcode?`}
          component={UserAuth(Profile)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/reset-password/:email?/:token?`}
          component={Resetpassword}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/change-password`}
          component={UserAuth(ChangePassword)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/forgot`}
          component={Forgot}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/payment-details`}
          exact
          component={UserAuth(PaymentDetails)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/:user_slug?/:zipcode?/quick-contact`}
          component={QuickContact}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/profile`}
          exact
          component={UserAuth(Profile)}
        />

        {/* <AuthRoute path={`${process.env.PUBLIC_URL}/profile/:user-slug?`}
                    component={UserAuth(Profile)} /> */}

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/edit-profile`}
          component={UserAuth(EditProfile)}
        />

        <AuthRoute
          path={`${process.env.PUBLIC_URL}/dashboard`}
          component={UserAuth(Dashboard)}
        />

        {/* Public */}

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={UserAuth(Home)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/why-us`}
          component={UserAuth(LandingPage)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/verify-email/:email?/:activation_hash?`}
          component={Home}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/home`}
          component={UserAuth(Home)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/terms`}
          component={Terms}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/privacy`}
          component={Privacy}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/search`}
          component={UserAuth(Search)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/contact-us`}
          component={UserAuth(ContactUs)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/blog`}
          component={UserAuth(BlogList)}
        />

        <PublicRoute
          exact
          path={`${process.env.PUBLIC_URL}/blog/:slug?`}
          component={UserAuth(BlogDetails)}
        />

        <PublicRoute
          path={`${process.env.PUBLIC_URL}/not-found`}
          component={UserAuth(Page404)}
        />

        <PublicRoute path={`*`} component={UserAuth(Page404)} />

        {/* User */}
        {/* <PrivateRoute path={`${process.env.PUBLIC_URL}/profile`}
                    component={UserAuth(Profile)} /> */}
      </Switch>
    </Layout>
    <ToastContainer />
    <Footer {...props} />
  </Router>
);

export default Routes;
